import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '730px',
    minWidth: '299px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '90px',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '20px',
  },
  section: {
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px',
    },
    backgroundColor: '#1c1c1c',
    padding: '20px 30px',
    marginBottom: '20px',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontSize: '20px',
    marginBottom: '20px',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepHeaderNum: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
    fontSize: '25px',
    marginRight: '16px',
  },
  card: {
    display: 'flex',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px',
    height: '76px',
  },
  coverImg: {
    userSelect: 'none',
    width: '76px',
    height: '74px',
    borderRadius: '8px 0 0 8px',
  },
  cardSecondColumn: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 10px',
    },
    display: 'flex',
    overflow: 'hidden',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '6px 15px',
  },
  cardLabel: {
    fontSize: '16px',
    color: '#ffffff',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardUsername: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    color: '#cfcfcf',
    marginTop: '8px',
  },
  cardDetails: {
    display: 'flex',
    fontSize: '12px',
    color: '#cfcfcf',
    lineHeight: '13.5px',
  },
  unLikedImg: {
    fontSize: '13px!important',
  },
  likedImg: {
    fontSize: '13px!important',
    color: '#d32f2f',
  },
  numberLikes: {
    marginLeft: '3px',
  },
  cardDetailSeparator: {
    margin: '0 4px',
  },
  playButtonGrid: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      padding: '5px 2px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playButtonImg: {
    [theme.breakpoints.down('xs')]: {
      width: '22px!important',
      height: '22px!important',
    },
    color: '#ffffff',
    width: '60px!important',
    height: '60px!important',
    cursor: 'pointer',
  },
  clearButtonImgXS: {
    [theme.breakpoints.up('sm')]: {
      display: 'none!important',
    },
    margin: '0',
    cursor: 'pointer',
    color: '#ffffff',
    width: '20px!important',
    height: '20px!important',
  },
  clearButtonImg: {
    [theme.breakpoints.down('xs')]: {
      display: 'none!important',
    },
    margin: '4px 4px 0 0',
    cursor: 'pointer',
    color: '#ffffff',
    width: '20px!important',
    height: '20px!important',
  },
  selectModelOuterContainer: {
    width: '100%',
    height: '76px',
    display: 'inline-flex',
    '&:hover': {
      '& $selectModelButton': {
        borderColor: '#fff',
      },
      '& $dragAndDropText': {
        color: '#fff',
      },
    },
    border: '1px solid #404040',
    borderRadius: '8px',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#292929',
  },
  selectModelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: '#fff',
      color: '#fff',
      textColor: '#fff',
      fontSize: '40px',
    },
  },
  addButtonImg: {
    [theme.breakpoints.down('xs')]: {
      width: '21px',
      height: '21px',
    },
    width: '30px',
    height: '30px',
    marginRight: '10px',
  },
  dragAndDropText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    fontWeight: '300',
    fontSize: '16px',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '2px',
  },
  uploadContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '15px 10px',
    },
    width: '100%',
    border: '1px solid #404040',
    borderRadius: '8px',
    backgroundColor: '#292929',
    padding: '20px',
  },
  inputSourceButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px',
    },
    display: 'flex',
    marginBottom: '25px',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '14px',
    fontWeight: '400',
    color: '#9a9a9a',
    marginRight: '13px',
    cursor: 'pointer',
  },
  selected: {
    fontWeight: '400',
    color: '#ffffff',
  },
  youtubeLinkInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px !important',
      padding: '5px !important',
    },
    width: '100%',
    border: '1px solid #9A9A9A',
    height: '48px',
    borderRadius: '8px !important',
    color: '#9A9A9A !important',
  },
  recordButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  youtubeLinkButton: {
    [theme.breakpoints.down('xs')]: {
      width: '70px !important',
      fontSize: '10px !important',
    },
    width: '100px !important',
  },
  mdxContainer: {
    display: 'flex',
    paddingTop: '15px!important',
    paddingBottom: '0!important',
    height: '30px',
  },
  checkbox: {
    padding: '0!important',
    marginLeft: '0!important',
    color: '#ffffff!important',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px!important',
    },
    fontWeight: '300',
    fontSize: '16px!important',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  recordContainer: {
    marginTop: '1rem',
    width: '100%',
  },
  mic: {
    display: 'flex',
    width: '100%',
    marginBottom: '15px',
    borderRadius: '8px',
  },
  recordingButton: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '48%',
      fontSize: '12px',
    },
    maxWidth: '125px',
    padding: '10px 0',
    width: '100%',
    borderRadius: '8px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#CAFF73',
    fontSize: '16px',
    border: 'none',
    color: '#000000',
  },
  onRecordingButton: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '48%',
      fontSize: '12px',
    },
    maxWidth: '125px',
    padding: '10px 0',
    width: '100%',
    borderRadius: '8px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#cfcfcf',
    fontSize: '16px',
  },
  labelInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
    },
    fontSize: '16px !important',
    padding: '0 8px',
    width: '100%',
    height: '36px',
    color: '#9a9a9a',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #9A9A9A',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
  },
  mixButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  mixButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
    height: '35px',
    width: '100px',
    fontSize: '15px',
    padding: '5px',
    borderRadius: '0.4rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1E1E1E',
    border: '1px solid #fafafa',
    color: '#fafafa',
  },
  mixIcon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem !important',
    },
    marginRight: '0.3rem',
  },
  extensionButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '300',
    color: '#9a9a9a',
    marginLeft: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '74px',
    height: '36px',
    border: '1px solid #404040',
    backgroundColor: '#292929',
  },
  extensionSelected: {
    fontWeight: '400',
    border: '1px solid #B9FF66',
    color: '#B9FF66',
  },
  helpText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
    color: '#9a9a9a',
    marginBottom: '10px',
    fontSize: '13px',
  },
  buttonRemainingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '16px',
    color: '#9a9a9a',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '11px',
      minWidth: '100px',
    },
    backgroundColor: '#CAFF73',
    padding: '10px 30px',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '8px',
    minWidth: '120px',
    cursor: 'pointer',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tooltipImg: {
    [theme.breakpoints.down('xs')]: {
      width: '14px',
    },
    margin: '0 10px 0 5px',
    width: '16px',
  },
  dragAndDropButton: {
    marginTop: '15px',
    fontWeight: '300',
    fontSize: '15px',
    borderRadius: '8px',
    padding: '6px 18px',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  buttonSubText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontWeight: '300',
    color: '#fff',
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '16px',
    marginLeft: '16px',
    lineHeight: '22px',
    color: '#fff',
    fontWeight: '300',
  },
  youtubeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
  },
  youtubeInfo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  youtubeInfoDeleteIcon: {
    width: '17px',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'relative!important',
    width: '25px',
    height: '25px',
    marginRight: '0.3rem',
    marginBottom: '0.6rem',
  },
  replayIcon: {
    fontSize: '25px',
  },
  pauseIcon: {
    fontSize: '25px',
  },
  playIcon: {
    fontSize: '25px',
  },
  mainSlider: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      height: '55px !important',
    },
    height: '50px !important',
    margin: '0px !important',
  },
  progressTime: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '13px !important',
    },
    fontSize: '10px !important',
    marginTop: '6px !important',
  },
}));

export { useStyles };
