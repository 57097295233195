const getOnetimeItems = (t, currency) => {
  const items = t('onetimeItems', { returnObjects: true });
  return items.map(elem => {
    return {
      type: elem.type,
      name: elem.name,
      price: elem.price[currency],
      discount: elem.discount ? elem.discount : null,
    };
  });
};
export default getOnetimeItems;
