import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '20px',
    width: '100%',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1.5rem 1rem 1rem 1rem',
    backgroundColor: '#1C1C1C',
    color: '#fff',
    fontWeight: '400',
    borderRadius: '0.5rem',
    border: '1px solid #1C1C1C',
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '0!important',
    },
    [theme.breakpoints.up(1200)]: {
      minHeight: '15rem',
      marginBottom: '0.6rem',
    },
    minHeight: '0',
    marginBottom: '1rem',
  },
  currentTierStyle: {
    border: '1px solid #D1FE75',
  },
  pastDueStyle: {
    border: '1px solid #e34c4c',
  },
  cardHeaderContainer: {
    [theme.breakpoints.up(1200)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0.5rem',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  cardHeader: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
  },
  cardFee: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    fontSize: '1.3rem',
  },
  cardFeeJp: {
    [theme.breakpoints.down(1200)]: {
      fontSize: '1rem',
    },
  },
  cardFeeSubText: {
    fontSize: '0.9rem',
    fontWeight: '300',
    color: '#A4A4A4',
    marginLeft: '0.3rem',
  },
  strikethroughPrice: {
    position: 'relative',
    marginRight: '0.7em',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '50%',
      width: '105%',
      height: '0.1em',
      backgroundColor: 'red',
      transform: 'translateY(-50%)',
    },
  },
  cardButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem',
      fontSize: '0.75rem',
      margin: '0.8rem 0',
    },
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '0.3rem',
    padding: '0.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '1rem 0',
    color: '#fff!important',
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  disabledButton: {
    cursor: 'not-allowed!important',
    color: '#71717A!important',
  },
  featureContainer: {
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    minHeight: '1.25rem',
  },
  featureTextThai: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    fontSize: '0.8rem',
  },
  featureText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
    },
    fontSize: '0.8rem',
    fontWeight: '300',
    marginLeft: '0.3rem',
    display: 'flex',
    alignItems: 'center',
  },
  featureIcon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem!important',
    },
    color: '#D1FE75',
    fontSize: '1rem!important',
  },
  expandable: {
    cursor: 'pointer',
  },
  expandContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.1rem',
    fontSize: '0.7rem',
    fontWeight: '300',
    cursor: 'pointer',
  },
  expandIcon: {
    fontSize: '1.25rem!important',
    color: '#71717A',
    transition: 'all 200ms!important',
    marginRight: '-0.3rem',
  },
  usageList: {
    fontSize: '0.75rem',
    fontWeight: '300',
    lineHeight: '1.5rem',
    color: '#fff',
    margin: '0',
    paddingLeft: '1rem',
    marginBottom: '0.3rem',
  },
  cardTagContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexFlow: 'row-reverse wrap-reverse',
    gap: '0.5rem',
    height: 'fit-content',
  },
  cardTag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#D1FE75',
    color: '#000',
    padding: '0.3rem 0.4rem',
    borderRadius: '0.3rem',
    fontSize: '0.6rem',
    width: 'fit-content',
  },
  cardTagPastDue: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e34c4c',
    color: '#000',
    padding: '0.3rem 0.4rem',
    borderRadius: '0.3rem',
    fontSize: '0.7rem',
    width: 'fit-content',
  },
  section: {
    [theme.breakpoints.down('xs')]: {
      gap: '2rem',
      padding: '1rem',
    },
    gap: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 2rem',
    backgroundColor: '#1C1C1C',
    margin: '1.2rem 0',
    borderRadius: '0.5rem',
  },
  creditInfoOuterContainer: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%!important',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
    },
    display: 'flex',
    flexDirection: 'column!important',
    gap: '1.5rem',
    maxWidth: '45%',
  },
  creditInfoContainer: {
    display: 'flex',
    flexDirection: 'column!important',
    gap: '1rem',
    width: 'fit-content',
    paddingTop: '1rem',
  },
  creditInfoHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
    fontSize: '1.5rem',
    fontWeight: '400',
    color: '#fff',
  },
  creditInfoList: {
    fontSize: '0.85rem',
    fontWeight: '300',
    lineHeight: '1.5',
    color: '#fff',
    margin: '0',
    paddingLeft: '1rem',
    textWrap: 'pretty',
  },
  creditInfoImageContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '90%!important',
    },
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    display: 'flex',
    width: '50%',
  },
  creditInfoImage: {
    width: '100%',
  },
  creditUsageContainer: {
    display: 'flex',
    flexDirection: 'column!important',
    gap: '1rem',
    textAlign: 'left!important',
  },
  creditUsageHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#fff',
    textAlign: 'left',
    gap: '0.2rem',
    cursor: 'pointer',
  },
  creditUsageTable: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    border: '1px solid #404040',
    color: '#fff',
    overflow: 'hidden',
    marginBottom: '0.75rem',
  },
  tableHeader: {
    display: 'flex',
    fontSize: '0.8rem',
    fontWeight: '400!important',
    backgroundColor: '#161616',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    borderBottom: '1px solid #404040',
  },
  tableHeaderCell: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    flexGrow: 1,
    width: '14rem',
    fontSize: '0.8rem',
    fontWeight: '400',
    padding: '0.8rem 1rem',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#1A1A1A',
    },
    display: 'flex',
    fontSize: '0.8rem',
    fontWeight: '300',
  },
  tableCell: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    flexGrow: 1,
    width: '14rem',
    padding: '0.7rem 1rem',
  },
  oneTimeOuterContainer: {
    marginTop: '0.5rem',
  },
  onetimeContainer: {
    alignItems: 'stretch!important',
    marginTop: '0.8rem',
  },
  onetimeStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.4rem',
    color: '#fff',
    fontWeight: '400',
  },
  onetimeMessage: {
    display: 'flex',
    marginTop: '0.8rem',
    fontSize: '0.75rem',
  },
  onetimeHeaderColumn: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column!important',
    gap: '0.6rem',
  },
  onetimeButtonColumn: {
    display: 'flex',
    flexDirection: 'column!important',
    gap: '0.5rem',
  },
  onetimeHeader: {
    display: 'flex',
    gap: '0.3rem',
    fontSize: '0.75rem',
    fontWeight: '300',
    padding: '0.4rem 0',
  },
  onetimeStrikethrough: {
    textDecorationLine: 'line-through',
    textDecorationColor: 'red',
    textDecorationThickness: '2px',
    webkitTextDecorationLine: 'line-through',
    webkitTextDecorationColor: 'red',
    webkitTextDecorationThickness: '2px',
  },
  onetimeCardButton: {
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '0.3rem',
    padding: '0.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    fontSize: '0.75rem',
    fontWeight: '400',
    minWidth: '3rem',
  },
  wideButton: {
    fontSize: '0.7rem',
    minWidth: '5rem!important',
  },
  comparisonSection: {
    display: 'flex',
    flexDirection: 'column!important',
    alignItems: 'center',
    gap: '1.5rem',
  },
  comparisonTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    fontSize: '23px',
    paddingTop: '1rem',
    color: '#fff',
    width: '100%',
  },
  comparisonTableOuterContainer: {
    '&.MuiBox-root': {
      [theme.breakpoints.down('xs')]: {
        height: '38rem',
        overflowY: 'auto!important',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%!important',
      },
      width: '70%',
      overflowX: 'auto!important',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  comparisonTableContainer: {
    '&.MuiPaper-root': {
      backgroundColor: 'transparent',
      color: '#fff',
      boxShadow: 'none',
    },
    paddingBottom: '0.4rem',
    marginBottom: '3rem',
  },
  comparisonTableHeader: {
    '&.MuiTableRow-root': {
      backgroundColor: '#1C1C1C',
      color: '#fff',
      border: 'none',
      position: 'sticky',
      top: '0',
      zIndex: '2',
      boxShadow: '0 4px 4px -4px #121212',
    },
  },
  comparisonTableHeaderCell: {
    '&.MuiTableCell-root': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '4rem',
        padding: '1.5rem 0.75rem',
        fontSize: '0.8rem',
      },
      color: '#fff!important',
      textAlign: 'center',
      fontSize: '0.9rem',
      fontFamily: 'inherit',
      fontWeight: '400',
      border: 'none',
      lineHeight: '0',
      padding: '1.5rem 1rem',
    },
  },
  comparisonTableRow: {
    '&.MuiTableRow-root': {
      borderBottom: '1px solid #404040!important',
      backgroundColor: '#121212',
    },
  },
  comparisonTableCell: {
    '&.MuiTableCell-root': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '4rem',
        padding: '0.8rem 0.75rem',
        fontSize: '0.7rem',
      },
      color: '#fff',
      textAlign: 'center',
      fontSize: '0.75rem',
      fontFamily: 'inherit',
      fontWeight: '300',
      padding: '0.6rem 1rem',
      border: 'none',
      whiteSpace: 'nowrap',
      minWidth: '6rem',
      textWrap: 'wrap!important',
    },
  },
  comparisonTableTitle: {
    '&.MuiTableCell-root': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem',
      },
      fontSize: '1.1rem',
      fontWeight: '400',
      border: 'none',
      color: '#fff',
      padding: '1.5rem 0.75rem 1rem 0.75rem',
      fontFamily: 'inherit',
    },
  },
  stickyHeader: {
    '&.MuiTableCell-root': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '4rem',
      },
      textAlign: 'left',
      backgroundColor: 'inherit',
      textWrap: 'wrap!important',
    },
    position: 'sticky',
    left: '0',
    zIndex: '1',
  },
  tableCheckIcon: {
    color: '#D1FE75',
    fontSize: '1.1rem!important',
    marginBottom: '-0.2rem',
  },
  noBorder: {
    '&.MuiTableRow-root': {
      borderBottom: 'none',
    },
  },
}));

export { useStyles };
