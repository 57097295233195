const costPerService = t => {
  const generationItems = t('costPerService.generation', { returnObjects: true });
  const trainingItems = t('costPerService.training', { returnObjects: true });

  const mapItems = items => {
    return items.map(elem => {
      return {
        name: elem.name,
        cost: elem.cost,
      };
    });
  };

  const mappedGenerationItems = mapItems(generationItems);
  const mappedTrainingItems = mapItems(trainingItems);

  return {
    generation: mappedGenerationItems,
    training: mappedTrainingItems,
  };
};

export default costPerService;
