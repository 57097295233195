import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  orderInfo: {
    marginBottom: '8px',
    marginTop: '10px',
    fontSize: '19px',
    textAlign: 'center',
  },
  orderPrice: {
    marginBottom: '8px',
    fontSize: '19px',
  },
  button: {
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    borderRadius: '3px',
    color: '#c4c4c4',
    height: '35px',
    width: '140px',
  },
  yesButton: {
    margin: '0 8px 0 0',
    border: '1px solid #c4c4c4',
    color: '#191919',
    backgroundColor: '#c4c4c4',
    '&:hover': {
      background: '#d1fe75',
      color: '#1e1e1e',
      border: '1px solid #d1fe75',
    },
  },
  noButton: {
    margin: '0 0 0 8px',
    border: '1px solid #a4a4a4',
    '&:hover': {
      background: '#d1fe75',
      color: '#1e1e1e',
      border: '1px solid #d1fe75',
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  message: {
    fontSize: '15px',
    marginBottom: '27px',
    marginTop: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    wordBreak: 'keep-all',
    lineHeight: '21px',
    fontWeight: '100',
    padding: '0 10px',
  },
}));

export { useStyles };
