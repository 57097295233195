import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GenerateTTIPage.styles';
import _ from 'lodash';
import { appActions } from '../../actions/app.actions.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import TuneIcon from '@mui/icons-material/Tune';
import {
  fetchSubscriptionInfo,
  checkTTIInferenceCompletion,
  uploadTTIInference,
  checkTTIInferenceCompletionById,
  getTTIInferenceSignedUrl,
  getTTIInferenceBlockEndTime,
} from '../../services/page.services';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { userActions } from '../../actions/user.actions.js';
import TTISettingModal from '../layout/TTISettingModal.js';
import FreeInferenceMessageModal from '../layout/FreeInferenceMessageModal.js';
import { showMessageV2 } from '../../utils/page.utils.js';
import sampleImg from '../../img/ttiSampleImg.png';
import DownloadIcon from '@mui/icons-material/Download';
import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';

function GenerateTTIPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');

  const [ttiInferenceRemaining, setTtiInferenceRemaining] = useState(0);
  const [ttiCharactersPerInference, setTtiCharactersPerInference] = useState(500);
  const [inferenceInProgress, setInferenceInProgress] = useState(false);
  const [openFreeInferenceModal, setOpenFreeInferenceModal] = useState(false);

  const [image, setImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [text, setText] = useState('');
  const [ratio, setRatio] = useState('horizontal');
  const [size, setSize] = useState({ value: 'small', setting: 0, label: t('ttiInferenceTab.modal.small') });
  const [quality, setQuality] = useState({ value: 'low', setting: 0, label: t('ttiInferenceTab.modal.low') });
  const [quantity, setQuantity] = useState({ value: 1, setting: 0, label: 1 });
  const [cfgScale, setCfgScale] = useState({ value: 2.0, setting: 0, label: t('ttiInferenceTab.modal.creative') });
  const [watermark, setWatermark] = useState(true);
  const [settingModalProps, setSettingModalProps] = useState({
    ratio: ratio,
    size: size,
    quality: quality,
    quantity: quantity,
    cfgScale: cfgScale,
    watermark: watermark,
    setRatio: setRatio,
    setSize: setSize,
    setQuality: setQuality,
    setQuantity: setQuantity,
    setCfgScale: setCfgScale,
    setWatermark: setWatermark,
  });

  const [coin, setCoin] = useState(2);
  const [blockEndTime, setBlockEndTime] = useState(null);
  const [threshold, setThreshold] = useState(0.65);

  useEffect(() => {
    const koText =
      '한 아시아 여성이 거울 앞에 서서 셀카를 찍고 있다. 그녀는 목이 늘어난 헐렁한 티셔츠를 입고 있다. 이미지 품질이 거칠고 약간 흐릿해지면서 세부 묘사가 부드럽다. 조명이 어두워서 그림자가 드리워져 있다. 방은 침대 위에 옷이 널려 있고, 정리되지 않은 담요로 어지러워져 있다. 그녀의 표정은 슬프지만 셀카를 찍기위해 억지 미소를 내려고 한다. 왼손으로 들고있는 기존 iPhone은 초점을 맞추는 데 어려움을 겪으면서 사진에 실제적이고 다듬어지지 않은 느낌을 준다. 거울에는 얼룩과 지문이 묻어 있어 현장의 생생한 일상 분위기를 더해준다.';
    const enText =
      'An Asian woman stands in front of a mirror taking a selfie, wearing a loose, stretched-out T-shirt. The image is rough and slightly blurry, with dim lighting casting shadows. The messy room has clothes on the bed and a disorganized blanket. She forces a smile despite a sad expression, holding an old iPhone struggling to focus. The mirror, smeared with stains and fingerprints, adds a raw, everyday feel to the scene.';
    const jaText =
      'An Asian woman stands in front of a mirror taking a selfie, wearing a loose, stretched-out T-shirt. The image is rough and slightly blurry, with dim lighting casting shadows. The messy room has clothes on the bed and a disorganized blanket. She forces a smile despite a sad expression, holding an old iPhone struggling to focus. The mirror, smeared with stains and fingerprints, adds a raw, everyday feel to the scene.';
    const thText =
      'An Asian woman stands in front of a mirror taking a selfie, wearing a loose, stretched-out T-shirt. The image is rough and slightly blurry, with dim lighting casting shadows. The messy room has clothes on the bed and a disorganized blanket. She forces a smile despite a sad expression, holding an old iPhone struggling to focus. The mirror, smeared with stains and fingerprints, adds a raw, everyday feel to the scene.';

    if (t('languageDetector') === 'ko') {
      setText(koText);
    } else if (t('languageDetector') === 'en') {
      setText(enText);
    } else if (t('languageDetector') === 'ja') {
      setText(jaText);
    } else if (t('languageDetector') === 'th') {
      setText(thText);
    }
  }, [t]);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  useEffect(() => {
    let newCoin = 2;
    if (size.value === 'large') newCoin += 1;
    if (quality.value === 'high') newCoin += 1;
    if (cfgScale.value === 5.0) newCoin += 1;
    newCoin *= quantity.value;
    setCoin(newCoin);
  }, [size, quality, quantity, cfgScale]);

  const loadingTextMap = {
    inProgress: [
      t('ttiInferenceTab.submit.inProgress.0'),
      t('ttiInferenceTab.submit.inProgress.1'),
      t('ttiInferenceTab.submit.inProgress.2'),
    ],
    inProgressNoTick: [t('ttiInferenceTab.submit.inProgressNoTick.0'), t('ttiInferenceTab.submit.inProgressNoTick.1')],
    complete: [t('ttiInferenceTab.submit.complete.0'), t('ttiInferenceTab.submit.complete.1')],
    uploading: [t('ttiInferenceTab.submit.uploading.0'), t('ttiInferenceTab.submit.uploading.1')],
  };

  const checkInferenceInProgress = async () => {
    let inferences = await checkTTIInferenceCompletion();
    const inProgress = _.some(inferences, { status: 'PENDING' });
    setInferenceInProgress(inProgress);
    if (inProgress) {
      setLoadingText('inProgressNoTick');
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    console.log('subscriptionInfo: ', subscriptionInfo);
    setTtiInferenceRemaining(
      (subscriptionInfo?.type === 'FREE'
        ? subscriptionInfo?.freeTtiInferenceRemaining + subscriptionInfo?.genCreditsRemaining
        : subscriptionInfo?.genCreditsRemaining) || 0
    );
    setTtiCharactersPerInference(subscriptionInfo?.ttiCharactersPerInference || 500);
    return subscriptionInfo;
  };

  const initPage = async () => {
    try {
      if (!isAdmin && !isCustom) {
        await checkInferenceInProgress();
      }
      const [, blockEndTime] = await Promise.all([checkRemainingCount(), getTTIInferenceBlockEndTime()]);
      blockEndTime ? setBlockEndTime(new Date(blockEndTime)) : setBlockEndTime(null);
    } catch (e) {
      console.log(e);
      showMessageV2(dispatch, t('modal.pageLoadFail'), { reloadOnClose: true });
    }
  };

  const checkCompleteForm = () => {
    let message = '';
    const currentTime = new Date();
    if (inferenceInProgress) {
      message = t('ttiInferenceTab.modal.inProgress');
    } else if (ttiInferenceRemaining < coin) {
      message = t('ttiInferenceTab.modal.lackRemaining');
    } else if (text.trim().length > ttiCharactersPerInference) {
      message = t('ttiInferenceTab.modal.exceedTextSize', {
        characterLimitPerInference: ttiCharactersPerInference,
      });
    } else if (text.trim().length === 0) {
      message = t('ttiInferenceTab.modal.noText');
    } else if (blockEndTime && blockEndTime > currentTime) {
      message = t('ttiInferenceTab.submit.nsfwBlock');
    }

    if (message) {
      message === t('ttiInferenceTab.submit.nsfwBlock')
        ? dispatch(appActions.openCustomMessageModal('ttiNSFWBlock'))
        : showMessageV2(dispatch, message);
      return false;
    }
    return true;
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const handleSubmit = async e => {
    if (!checkValidLoginStatus(userId, dispatch)) return;
    if (!checkCompleteForm()) return;
    setLoadingText('uploading');
    setInferenceInProgress(true);

    try {
      const inference = await uploadTTIInference(
        text.trim(),
        ratio,
        size.value,
        quality.value,
        quantity.value,
        cfgScale.value,
        watermark,
        user.subscription.type,
        coin,
        threshold
      );
      setLoadingText('inProgress');
      setImage([]);
      setCurrentIndex(0);

      if (inference) {
        const tick = async () => {
          const ttiId = inference.ttiInference.id;
          const ttiQuantity = inference.ttiInference.quantity;
          const ttiLabel = inference.ttiInference.label;

          const status = await checkTTIInferenceCompletionById(ttiId, ttiQuantity);
          if (status === 'COMPLETE') {
            clearInterval(intervalIDRef.current);
            setLoadingText('complete');

            for (let i = 1; i <= ttiQuantity; i++) {
              const downloadName = `${ttiLabel}_${i}.png`;
              const ttiImageFileName = `text-to-image_0000${i}_.png`;
              const imageUrl = await getTTIInferenceSignedUrl(ttiId, ttiImageFileName, downloadName);
              const imageBlob = await getBlobFromS3Url(imageUrl);
              const imageObject = URL.createObjectURL(imageBlob);

              setImage(previous => [...previous, { id: ttiId, image: imageObject, downloadName: downloadName }]);
            }

            setLoadingText('');
            checkRemainingCount();
            setInferenceInProgress(false);
          } else if (status === 'FAILED') {
            clearInterval(intervalIDRef.current);
            setLoadingText('');
            checkRemainingCount();
            setInferenceInProgress(false);
          }
        };
        intervalIDRef.current = setInterval(tick, 5000);
      }
    } catch (e) {
      if (e?.response?.data?.errorType === 'NSFW') {
        const nsfwCount = e.response.data.nsfwCount;
        dispatch(appActions.openCustomMessageModal('ttiNSFWError', [nsfwCount]));
      } else {
        showMessageV2(dispatch, t('ttiInferenceTab.submit.tryAgain'));
      }
      setLoadingText('');
      setImage([]);
      setCurrentIndex(0);
      checkRemainingCount();
      const blockEndTime = await getTTIInferenceBlockEndTime();
      blockEndTime ? setBlockEndTime(new Date(blockEndTime)) : setBlockEndTime(null);
      setInferenceInProgress(false);
      return;
    }
  };

  const handleDownloadImage = async (currentIndex, id, downloadName) => {
    if (!id) return;

    try {
      const ttiImageFileName = `text-to-image_0000${currentIndex + 1}_.png`;
      const tempLink = document.createElement('a');
      tempLink.href = await getTTIInferenceSignedUrl(id, ttiImageFileName, downloadName);

      if (tempLink.href) {
        tempLink.click();
        URL.revokeObjectURL(tempLink.href);
      }
    } catch (e) {
      console.log('e : ', e);
    }
  };

  const handleSetting = async () => {
    setSettingModalProps({
      open: true,
      setRatio: setRatio,
      setSize: setSize,
      setQuality: setQuality,
      setQuantity: setQuantity,
      setCfgScale: setCfgScale,
      setWatermark: setWatermark,
      ratio: ratio,
      size: size,
      quality: quality,
      quantity: quantity,
      cfgScale: cfgScale,
      watermark: watermark,
      onSettingModalClose: () => {
        setSettingModalProps({ open: false });
      },
    });
  };

  const classes = useStyles();
  return (
    <>
      <FreeInferenceMessageModal open={openFreeInferenceModal} setOpenFreeInferenceModal={setOpenFreeInferenceModal} />
      <TTISettingModal {...settingModalProps} />
      <div className={classes.container}>
        <div className={classes.pageTitle}>{t('ttiInferenceTab.title')}</div>
        <div>
          <div>
            <textarea
              className={classes.ttiTextArea}
              rows={5}
              value={text}
              onChange={e => {
                if (checkValidLoginStatus(userId, dispatch)) {
                  setText(e.target.value);
                }
              }}
            />
          </div>
          <div className={classes.characters}>
            {text.length} / {ttiCharactersPerInference}
          </div>
          {loadingText ? (
            loadingText === 'inProgressNoTick' ? (
              <div className={classes.imageContainer}>
                <img src={sampleImg} alt="sample-image" className={classes.image} />
              </div>
            ) : (
              <div className={classes.imageContainer}>
                <div className={classes.loadingContainer}>
                  <CircularProgress className={classes.circularProgress} />
                  {loadingTextMap[loadingText].map(txt => (
                    <div className={classes.loadingText}>{txt}</div>
                  ))}
                </div>
              </div>
            )
          ) : image.length > 0 ? (
            <div className={classes.imageContainer}>
              <img src={image[currentIndex].image} alt="image" className={classes.image} />
              <button
                className={classes.downloadButton}
                onClick={() => {
                  handleDownloadImage(currentIndex, image[currentIndex].id, image[currentIndex].downloadName);
                }}
              >
                <DownloadIcon className={classes.downloadIcon} />
              </button>
              {image.length > 1 && (
                <div className={classes.overlay}>
                  {image.map((obj, index) => (
                    <img
                      key={index}
                      src={obj.image}
                      alt={`image-${index}`}
                      className={classes.smallImage}
                      onClick={() => {
                        setCurrentIndex(index);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={classes.imageContainer}>
              <img src={sampleImg} alt="sample-image" className={classes.image} />
            </div>
          )}
          <div className={classes.lastLineContainer}>
            <div className={classes.settingButtonContainer}>
              <div
                className={classes.settingButton}
                onClick={() => {
                  if (checkValidLoginStatus(userId, dispatch)) {
                    handleSetting();
                  }
                }}
              >
                <TuneIcon fontSize="20rem" className={classes.tuneIcon} />
                <div className={classes.settingTypo}>{t('ttiInferenceTab.submit.setting')}</div>
              </div>
              {isAdmin && (
                <input
                  className={classes.threshold}
                  type="number"
                  value={threshold}
                  step="0.01"
                  onChange={e => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setThreshold(value);
                    }
                  }}
                />
              )}
            </div>
            <div className={classes.submitContainer}>
              {userId && (
                <div className={classes.infoContainer}>
                  <HtmlTooltip
                    title={
                      <div className={'globalTooltipContainer'}>
                        <div className={'globalTooltipTextContainer'}>
                          <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                          <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.coinContents.0')}</div>
                        </div>
                        <div className={'globalTooltipTextContainer'}>
                          <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                          <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.coinContents.1')}</div>
                        </div>
                        <div className={classes.indentation}>
                          <div className={'globalTooltipTextContainer'}>
                            <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                            <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.coinContents.2')}</div>
                          </div>
                          <div className={'globalTooltipTextContainer'}>
                            <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                            <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.coinContents.3')}</div>
                          </div>
                          <div className={'globalTooltipTextContainer'}>
                            <div className="globalTooltipText globalTooltipTextBullet">&#x2022;</div>
                            <div className={'globalTooltipText'}>{t('ttiInferenceTab.tooltip.coinContents.4')}</div>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                  </HtmlTooltip>
                  <div className={classes.coinContainer}>
                    <div className={classes.coin}>
                      {t('ttiInferenceTab.submit.requiredCredits')}: {coin}
                    </div>
                    <div className={classes.coin}>
                      {t('ttiInferenceTab.submit.remainingCredits')}: {ttiInferenceRemaining}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={inferenceInProgress ? classes.submitButtonInProgress : classes.submitButton}
                onClick={handleSubmit}
                disabled={inferenceInProgress}
              >
                <div className={classes.submitTypo}>
                  {inferenceInProgress
                    ? t('ttiInferenceTab.submit.alreadyInProgress')
                    : t('ttiInferenceTab.submit.start')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateTTIPage;
