const dragAndDropStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '8px',
  borderColor: '#303030',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  //outline: "none",
  //transition: "border .24s ease-in-out",
  fontSize: '14px',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: '#141414',
  '&:hover': {
    borderColor: '#fff',
    border: 'solid',
    color: '#fff',
    textColor: '#fff',
  },
};

const dragAndDropStyleNew = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '15px',
  border: '1px solid #9A9A9A',
  borderRadius: '8px',
  color: '#bdbdbd',
  fontSize: '14px',
  justifyContent: 'center',
  cursor: 'pointer',
};

const dragAndDropStyleSameBackground = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  border: '1px dashed #9A9A9A',
  borderRadius: '8px',
  color: '#bdbdbd',
  fontSize: '14px',
  justifyContent: 'center',
  cursor: 'pointer',
};

export { dragAndDropStyle, dragAndDropStyleNew, dragAndDropStyleSameBackground };

const dragAndDropWhiteStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '8px',
  borderColor: '#9e9e9e',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  //outline: "none",
  //transition: "border .24s ease-in-out",
  fontSize: '14px',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: '#141414',
  '&:hover': {
    borderColor: '#fff',
    border: 'solid',
    color: '#fff',
    textColor: '#fff',
  },
};
export { dragAndDropWhiteStyle };
