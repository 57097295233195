import React from 'react';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './SubscriptionUpgradeModal.style';
import { getCurrencySymbol } from '../../utils/page.utils';
import BaseModalLayout from './BaseModalLayout';

const SubscriptionUpgradeModal = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModalLayout {...props} sizeMax>
      {props.loading ? (
        <CircularProgress className={classes.loading} />
      ) : (
        <>
          <div className={classes.orderInfo}>{props.name}</div>
          <div className={classes.orderPrice}>
            {getCurrencySymbol(props.currency)}
            {props.price}
          </div>
          {props.submitting ? (
            <CircularProgress />
          ) : (
            <>
              <div className={classes.message}>{props.message}</div>
              <div className={classes.buttonContainer}>
                <div className={`${classes.button} ${classes.yesButton}`} onClick={() => props.onConfirmation(true)}>
                  {t('modal.confirmationPay')}
                </div>
                <div className={`${classes.button} ${classes.noButton}`} onClick={() => props.onConfirmation(false)}>
                  {t('modal.confirmationCancel')}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </BaseModalLayout>
  );
};

export default SubscriptionUpgradeModal;
