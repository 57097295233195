import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    alignSelf: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  label: {
    display: 'flex',
    marginBottom: '2rem',
  },
  labelInput: {
    flex: '1',
  },
  buttonGroupGrid: {
    border: '#fafafa solid',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '85%',
    borderRadius: '0.5rem',
    display: 'flex',
    //alignItems: "stretch",
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },

  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  inputSourceButtonContainer: {
    [theme.breakpoints.down('544')]: {
      width: '100%',
      fontSize: '13px',
    },
    [theme.breakpoints.down('476')]: {
      width: '100%',
      fontSize: '11px',
    },
    width: '25rem',
    fontSize: '13px',
    marginTop: '.3rem',
    //marginBottom: ".1rem",
    backgroundColor: '#818181',
    padding: '0.2rem',
    alignSelf: 'start',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    flex: 1,
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 1rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  cardGrid: {
    //border: "1px solid #fafafa",
    columnGap: '15px',
  },
  card: {
    //justifyContent: "stretch",
    display: 'flex',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '25rem',
    marginBottom: '1rem',
    //marginRight: "1rem",
    minHeight: '2rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '25rem',
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      padding: '.4rem',
      paddingBottom: '.3rem',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardSecondColumn: {
    flex: 'vertical',
  },
  cardLabelUsername: {
    display: 'grid',

    //placeItems: "center",
    textOverflow: 'ellipsis',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.3rem',
    display: 'flex',
    alignItems: 'top',
    fontSize: '1.4rem',
    flexWrap: 'wrap',
    height: '3.18rem',
    display: 'inline-block',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    //whiteSpace: "nowrap",
    //textOverflow: "ellipsis",
  },
  cardUsername: {
    alightItems: 'bottom',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '1rem',
    textOverflow: 'ellipsis',
    color: '#d3d3d3',
    height: '1.1rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.9rem',
    },
  },
  editIcon: {
    width: '1.2rem!important',
    marginLeft: '0.3rem',
    cursor: 'pointer',
  },
  cardTimestamp: {
    //marginTop: "3.3rem",
    alightItems: 'bottom',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.7rem',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    //whiteSpace: "nowrap",
    [theme.breakpoints.down(476)]: {
      fontSize: '0.6rem',
    },
  },
  cardLikes: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1.2rem',
  },
  cardBottomRight: {
    justifyContent: 'flex-end',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '0.8rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '0.3rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.7rem',
    },
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '0.6rem',
    //marginLeft: "",
    //marginTop: ".2rem",
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    //textOverflow: "ellipsis",
    [theme.breakpoints.down(476)]: {
      fontSize: '0.5rem',
    },
  },
  creationsGrid: {
    alignItems: 'flex-end',
  },
  cardStatus: {
    textAlign: 'center',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '0.8rem',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '4rem',
      marginLeft: '0rem',
    },
  },
  cardAction: {
    cursor: 'pointer',
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeaderCenter: {
    alignSelf: 'center',
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem',
    },
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  searchbar: {
    fontSize: '16px !important',
    padding: '0 8px',
    maxWidth: '-webkit-fill-available',
    width: '25rem',
    height: '40px',
    color: '#9a9a9a',
    margin: '1rem 0 1rem 0',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
  },
  infoIconAction: {
    width: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  infoIconImg: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0.2rem',
    color: '#161616',
  },
  infoIconTextContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#161616',
  },
  infoIconTextLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoIconTextBold: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  infoIconText: {
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      //padding: "0.7rem 1rem",
      fontSize: '1rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0 1.3rem',
    //margin: "0 0 0 0",
    margin: '1rem 0 1rem 0',
    width: '7.0rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    alignItems: 'center',
    display: 'flex',
  },
  searchButtonContainer: {
    display: 'flex',
  },
  coverImg: {
    /*
    [theme.breakpoints.down("xs")]: {
      maxWidth: "5.5rem",
      width: "100%",
    },
    */
    userSelect: 'none',
    width: '5.5rem',
    height: '5.5rem',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.3rem',
    border: '0.07rem solid #808080',
  },
  playButtonImg: {
    userSelect: 'none',
    //display: "inline-block",
    //marginLeft: "0.5rem",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '50px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  playButtonGrid: {
    direction: 'row',
    justifyContent: 'flex-end',
  },
  likeButtonImg: {
    marginTop: '3.2rem',
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2px',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
  },
  favoriteFullImg: {
    userSelect: 'none',
    //display: "inline-block",
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    //color: "#ff0000",
    color: '#d32f2f',
  },
  numberLikes: {
    //marginTop: "3.5rem",
    fontSize: '0.8rem',
    marginLeft: '.1rem',
    marginRight: '.1rem',
  },
  tagAutoComplete: {
    '&::placeholder': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px !important',
      },
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    color: '#fff',
    fontSize: '16px!important',
    fontWeight: '400',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    padding: '0 !important',
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: '#292929',
  },
  sortSelect: {
    backgroundColor: '#fff',
    height: '2.5rem',
    [theme.breakpoints.down(1134)]: {
      width: '6',
    },
    width: '7',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    backgroundColor: '#292929',
    color: '#9a9a9a!important',
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
  },
  filterSortContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    //border: "1px solid #fafafa",

    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
}));

export { useStyles };
