const subscriptionComparison = t => {
  const headerItems = t('subscriptionComparison.headers.row', { returnObjects: true });
  const subscriptionItems = t('subscriptionComparison.subscription', { returnObjects: true });
  const inferenceItems = t('subscriptionComparison.inference', { returnObjects: true });
  const faceSwapItems = t('subscriptionComparison.faceSwap', { returnObjects: true });
  const ttiItems = t('subscriptionComparison.tti', { returnObjects: true });

  const mapItems = items => {
    return items.map(elem => {
      return {
        feature: elem.feature,
        row: elem.row,
      };
    });
  };

  const mappedHeaderItems = Array.isArray(headerItems)
    ? headerItems.map(item => {
        return item;
      })
    : [];

  const mappedSubscriptionRows = mapItems(subscriptionItems);
  const mappedInferenceRows = mapItems(inferenceItems);
  const mappedFaceSwapRows = mapItems(faceSwapItems);
  const mappedTtiRows = mapItems(ttiItems);

  return {
    headers: mappedHeaderItems,
    subscription: mappedSubscriptionRows,
    inference: mappedInferenceRows,
    faceSwap: mappedFaceSwapRows,
    tti: mappedTtiRows,
  };
};

export default subscriptionComparison;
