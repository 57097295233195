import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  formBlock: {
    width: '100%',
    maxWidth: '40rem',
    marginTop: '3rem',
  },
  selectBlock: {
    backgroundColor: '#fff',
    borderRadius: '0.2rem',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0rem',
    width: '100%',
    height: '2.7rem',
    border: '0',
  },
  inputBlock: {
    backgroundColor: '#fff',
    borderRadius: '0.2rem',
    margin: '0.5rem 0rem',
    width: '100%',
    height: '2.7rem',
    boxShadow: 'none',
  },
  multilineBlock: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '0.2rem',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0rem',
    minHeight: '15rem',
    maxHeight: '25rem',
  },
  multilineInput: {
    width: '100%',
    backgroundColor: '#fff',
    maxHeight: '95%',
    borderRadius: '0.2rem',
    padding: '0.8rem!important',
    margin: '0.5rem 0rem!important',
  },
  button: {
    backgroundColor: '#CAFF73',
    padding: '0.6rem 0rem',
    fontSize: '0.8rem',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    width: '8rem',
    textAlign: 'center',
    marginTop: '1.2rem',
  },
  disabledButton: {
    backgroundColor: 'grey',
    cursor: 'not-allowed',
  },
  disabledForm: {
    backgroundColor: 'grey',
  },
  placeholderStyle: {
    color: 'rgba(0,0,0,0.5)',
  },
}));

export { useStyles };
