import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userActions } from '../../actions/user.actions';

import {
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { Check, ExpandMore, CheckCircle } from '@mui/icons-material';

import PaymentModal from '../layout/PaymentModal';
import ConfirmationModal from '../layout/ConfirmationModal';

import creditUsageEngImg from '../../img/credit-usage-eng.png';
import creditUsageKorImg from '../../img/credit-usage-kor.png';

import { useStyles } from './SubscriptionPage.styles';
import getSubscriptionItems from './subscriptionItems.js';
import getOnetimeItems from './ontimeItems';
import getCostPerService from './costPerService';
import getSubscriptionComparison from './subscriptionComparison';

import * as pageService from '../../services/page.services';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { getCurrency, getSubscriptionAction, showMessageV2 } from '../../utils/page.utils';

import {
  PaymentService,
  portOnePurchaseChannelKey,
  portOneSubscriptionChannelKey,
  SubscriptionAction,
  SubscriptionType,
} from '../../constants/app.constants';

import * as PortOne from '@portone/browser-sdk/v2';
import SubscriptionUpgradeModal from '../layout/SubscriptionUpgradeModal.js';

const portOneStoreId = 'store-94887967-6f19-478d-b8dd-5320faafae96';

const SubscriptionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const user = useSelector(state => state.user);
  const userId = user.id;
  const [userLanguage, setUserLanguage] = useState('kor');
  const [currency, setCurrency] = useState(null);
  const [paymentModalProps, setPaymentModalProps] = useState({});
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const [subscriptionUpgradeModalProps, setSubscriptionUpgradeModalProps] = useState({});
  const subscriptionItems = currency ? getSubscriptionItems(t, currency) : [];
  const onetimeItems = currency ? getOnetimeItems(t, currency) : [];
  const costPerService = getCostPerService(t) || [];
  const subscriptionComparison = getSubscriptionComparison(t) || [];
  const subscriptionType = user?.subscription?.type;
  const isCustom = subscriptionType === 'CUSTOM';
  const subscriptionStatus = user?.subscription?.status;
  const hasDiscount = subscriptionType === SubscriptionType.PRO || subscriptionType === SubscriptionType.PREMIUM;

  useEffect(() => {
    (async () => {
      const currencyToSet = await getCurrency();
      setCurrency(value => {
        if (!value) return currencyToSet;
        return value;
      });
    })();
  }, [currency]);

  useEffect(() => {
    if (userId) {
      setCurrency(user.currency);
      initData();
    }
  }, [userId]);

  const initData = () => setTimeout(() => userActions.getUser(dispatch), 2000);

  const initiateSubscription = async newPlan => {
    setPaymentModalProps({
      open: true,
      subscriptionType: newPlan,
      onClickPaymentOption: async (order, paymentOption) => {
        setPaymentModalProps({ open: true, submitting: true });

        if (paymentOption === PaymentService.STRIPE) {
          try {
            await pageService.triggerStripeSubscriptionSession(order.id);
          } catch (e) {
            showMessageV2(dispatch, t('requestFailed'));
          }
        } else if (paymentOption === PaymentService.TOSS) {
          const tempCustomerId = uuidv4();
          const result = await PortOne.requestIssueBillingKey({
            storeId: portOneStoreId,
            channelKey: portOneSubscriptionChannelKey,
            billingKeyMethod: 'EASY_PAY',
            issueId: order.id,
            issueName: order.name,
            customer: { customerId: tempCustomerId, email: user.email, fullName: user.name, firstName: user.id },
            customData: { userId: user.id, tempId: tempCustomerId },
          });

          if (!result.code && result.billingKey) {
            try {
              await pageService.payWithBillingKey(order.id, result.billingKey);
              userActions.getUser(dispatch);
              await setTimeout(() => {
                userActions.getUser(dispatch);
              }, 4000);
            } catch {
              showMessageV2(dispatch, t('requestFailed'));
            } finally {
              setPaymentModalProps({ open: false });
            }
          }
        }
        setPaymentModalProps({ open: false });
      },
    });
  };

  const downgradeSubscription = async newPlan => {
    setConfirmationModalProps({
      open: true,
      message: t('subscriptionTab.downgrade.message'),
      onConfirmation: async response => {
        if (response === true) {
          setConfirmationModalProps({ open: true, loading: true });
          try {
            await pageService.downgradeSubscription(newPlan);
            showMessageV2(dispatch, t('subscriptionTab.downgrade.success'));
            navigate('/profile');
          } catch (e) {
            showMessageV2(dispatch, t('subscriptionTab.downgrade.fail'));
          }
        }
        setConfirmationModalProps({ open: false });
      },
    });
  };

  const upgradeSubscription = async newPlan => {
    try {
      const priceInfo = await pageService.getSubscriptionUpgradeInfo(newPlan);
      setSubscriptionUpgradeModalProps({
        open: true,
        message: t('subscriptionTab.upgrade.message'),
        name: priceInfo.name,
        price: priceInfo.price,
        currency: priceInfo.currency,
        onConfirmation: async response => {
          if (response === true) {
            setSubscriptionUpgradeModalProps({ open: true, loading: true });
            try {
              const success = await pageService.upgradeSubscription(newPlan);
              if (success) {
                showMessageV2(dispatch, t('subscriptionTab.upgrade.success'));
                initData();
              } else {
                showMessageV2(dispatch, t('subscriptionTab.upgrade.paymentFailed'), {
                  showHeaderLogo: true,
                  showProfileButton: true,
                });
              }
            } catch (e) {
              showMessageV2(dispatch, t('subscriptionTab.upgrade.fail'));
            }
          }
          setSubscriptionUpgradeModalProps({ open: false });
        },
      });
    } catch (e) {
      showMessageV2(dispatch, t('requestFailed'));
    }
  };

  const onClick = async model => {
    if (checkValidLoginStatus(user.id, dispatch)) {
      if (user.subscription?.paymentService === 'iap_ios' || user.subscription?.paymentService === 'iap_android') {
        showMessageV2(dispatch, t('subscriptionTab.mobilePaymentRequired'));
        return;
      }
      const actionType = getSubscriptionAction(subscriptionType, model);

      if (actionType === SubscriptionAction.CANCEL) {
        navigate('/profile');
      } else if (actionType === SubscriptionAction.INITIATE) {
        await initiateSubscription(model);
      } else if (actionType === SubscriptionAction.DOWNGRADE) {
        await downgradeSubscription(model);
      } else if (actionType === SubscriptionAction.UPGRADE) {
        await upgradeSubscription(model);
      }
    }
  };

  const initiatePurchase = async purchaseType => {
    setPaymentModalProps({
      open: true,
      purchaseType,
      onClickPaymentOption: async (order, paymentOption) => {
        setPaymentModalProps({ open: true, submitting: true });
        await pageService.updateOrder(order.id, { paymentService: paymentOption });

        if (paymentOption === PaymentService.STRIPE) {
          try {
            await pageService.triggerStripeOneTimeSession(order.id);
          } catch (e) {
            showMessageV2(dispatch, t('requestFailed'));
          }
        } else if (paymentOption === PaymentService.TOSS) {
          try {
            const result = await PortOne.requestPayment({
              storeId: portOneStoreId,
              channelKey: portOnePurchaseChannelKey,
              paymentId: `${order.id}`,
              orderName: `${order.name}`,
              totalAmount: order.price,
              currency: 'KRW',
              payMethod: 'EASY_PAY',
              customer: { customerId: user.id },
              customData: { userId: user.id },
            });
            if (result.code === 'FAILURE_TYPE_PG') {
              await pageService.updateOrder(order.id, { status: 'CANCELED' });
            }
          } catch (e) {
            showMessageV2(dispatch, t('requestFailed'));
          }
        }
        setPaymentModalProps({ open: false });
      },
    });
  };

  const onClickOnetimePurchase = async type => {
    if (checkValidLoginStatus(user.id, dispatch)) {
      await initiatePurchase(type);
    }
  };

  const [expandedFeature, setExpandedFeature] = useState([]);
  const [expandedOneTime, setExpandedOneTime] = useState([]);
  const [expandedCreditUsage, setExpandedCreditUsage] = useState(false);

  const handleFeatureExpand = featureIndex => {
    setExpandedFeature(prevExpanded =>
      prevExpanded.includes(featureIndex)
        ? prevExpanded.filter(tierIndex => tierIndex !== featureIndex)
        : [...prevExpanded, featureIndex]
    );
  };
  const handleOneTimeExpand = tierIndex => {
    setExpandedOneTime(prevExpanded =>
      prevExpanded.includes(tierIndex)
        ? prevExpanded.filter(index => index !== tierIndex)
        : [...prevExpanded, tierIndex]
    );
  };

  const renderRows = data => {
    return data.map((item, index) => (
      <TableRow className={`${classes.comparisonTableRow} ${index === data.length - 1 && classes.noBorder}`}>
        <TableCell className={`${classes.comparisonTableCell} ${classes.stickyHeader}`}>{item.feature}</TableCell>
        {item.row.map(value => (
          <TableCell className={classes.comparisonTableCell}>
            {value === 'Y' ? <CheckCircle className={classes.tableCheckIcon} /> : value === 'N' ? '-' : value}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const useThaiStyle = selectedLanguage === 'th';
  return (
    <>
      <PaymentModal
        onClose={() => !paymentModalProps.submitting && setPaymentModalProps({ open: false })}
        {...paymentModalProps}
      />
      <ConfirmationModal onClose={() => setConfirmationModalProps({ open: false })} {...confirmationModalProps} />
      <SubscriptionUpgradeModal
        onClose={() => setSubscriptionUpgradeModalProps({ open: false })}
        {...subscriptionUpgradeModalProps}
      />
      <div className={classes.pageTitle}>{t('subscriptionTab.title')}</div>
      <Grid container spacing={2} alignItems="flex-start">
        {subscriptionItems.map(
          ({ name, model, price, discountedPrice, discountRate, period, features, usage }, tierIndex) => (
            <Grid item container xs={12} sm={6} md={6} lg={3}>
              <div
                className={`
                ${classes.cardStyle}
                ${model === subscriptionType && subscriptionStatus === 'past_due' && classes.pastDueStyle}
                ${model === subscriptionType && subscriptionStatus !== 'past_due' && classes.currentTierStyle}
              `}
              >
                <div className={classes.cardContent}>
                  <div className={classes.cardHeaderContainer}>
                    <div className={classes.cardHeader}>{name}</div>

                    <div className={`${classes.cardFee} ${selectedLanguage === 'ja' && classes.cardFeeJp}`}>
                      <span className={!!discountedPrice ? classes.strikethroughPrice : undefined}>{price}</span>
                      {!!discountedPrice && <span>{discountedPrice}</span>}
                      <span className={classes.cardFeeSubText}>{`/ ${t('subscriptionTab.tabs.subscription.month')}`}</span>
                    </div>
                  </div>
                  {(!!subscriptionType && subscriptionType !== model) ||
                  (!subscriptionType && model !== SubscriptionType.FREE) ? (
                    <div
                      className={`${classes.cardButton} ${isCustom && classes.disabledButton}`}
                      onClick={() => !isCustom && onClick(model)}
                    >
                      {!subscriptionType || subscriptionType === 'FREE'
                        ? t('subscriptionTab.tabs.subscription.subscribe')
                        : t('subscriptionTab.tabs.subscription.change')}
                    </div>
                  ) : (
                    <div className={`${classes.cardButton} ${classes.disabledButton}`}>
                      {t('subscriptionTab.tabs.subscription.currentTier')}
                    </div>
                  )}
                  {features.map(({ name, usage }, featureIndex) => (
                    <div className={classes.featureOuterContainer}>
                      <div
                        className={`${classes.featureContainer} ${!!usage && classes.expandable}`}
                        onClick={() => !!usage && handleFeatureExpand(`${tierIndex}${featureIndex}`)}
                      >
                        <Check className={classes.featureIcon} />
                        <div className={`${useThaiStyle && classes.featureTextThai} ${classes.featureText} `}>
                          {name}
                          {!!usage && (
                            <ExpandMore
                              className={classes.expandIcon}
                              sx={
                                expandedFeature.includes(`${tierIndex}${featureIndex}`) && {
                                  transform: 'rotate(-180deg)',
                                }
                              }
                            />
                          )}
                        </div>
                      </div>
                      {!!usage && (
                        <Collapse in={expandedFeature.includes(`${tierIndex}${featureIndex}`)} unmountOnExit>
                          <ul className={classes.usageList}>{!!usage && usage.map(text => <li>{text}</li>)}</ul>
                        </Collapse>
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <div className={classes.cardTagContainer}>
                    <div className={classes.expandContainer} onClick={() => handleOneTimeExpand(tierIndex)}>
                      {t('subscriptionTab.tabs.subscription.moreCredits')}
                      <ExpandMore
                        className={classes.expandIcon}
                        fontSize="large"
                        sx={expandedOneTime.includes(tierIndex) && { transform: 'rotate(-180deg)' }}
                      />
                    </div>
                    <div>
                      {model === subscriptionType && subscriptionStatus === 'past_due' && (
                        <div className={classes.cardTagPastDue}>{t('subscriptionTab.tabs.subscription.pastDue')}</div>
                      )}
                      {subscriptionType !== SubscriptionType.PRO && model === SubscriptionType.PRO && (
                        <div className={classes.cardTag}>{t('subscriptionTab.tabs.subscription.popular')}</div>
                      )}
                      {subscriptionType !== SubscriptionType.PREMIUM && model === SubscriptionType.PREMIUM && (
                        <div className={classes.cardTag}>{t('subscriptionTab.tabs.subscription.value')}</div>
                      )}
                    </div>
                  </div>
                  <Collapse
                    in={expandedOneTime.includes(tierIndex)}
                    className={classes.oneTimeOuterContainer}
                    unmountOnExit
                  >
                    {(model === SubscriptionType.PRO || model === SubscriptionType.PREMIUM) && (
                      <div className={classes.onetimeMessage}>{t('subscriptionTab.tabs.subscription.discount')}</div>
                    )}
                    <Grid container className={classes.onetimeContainer}>
                      <Grid item container xs className={classes.onetimeHeaderColumn}>
                        {onetimeItems.map(({ name, discount }) => (
                          <div className={classes.onetimeHeader}>
                            {!!discount && (model === SubscriptionType.PRO || model === SubscriptionType.PREMIUM) && (
                              <div className={classes.onetimeStrikethrough}>{discount.strikethrough}</div>
                            )}
                            {!!discount && (model === SubscriptionType.PRO || model === SubscriptionType.PREMIUM)
                              ? discount.name
                              : name}
                          </div>
                        ))}
                      </Grid>
                      <Grid item container xs="auto" className={classes.onetimeButtonColumn}>
                        {onetimeItems.map(({ discount, price, type }) => (
                          <div
                            className={`${classes.onetimeCardButton}
                            ${currency === 'krww' && classes.wideButton}
                            ${(model === SubscriptionType.PRO || model === SubscriptionType.PREMIUM) && (!hasDiscount || subscriptionStatus === 'past_due') && classes.disabledButton}
                          `}
                            onClick={() => {
                              console.log(
                                'onClickOnetimePurchase',
                                discount?.type && hasDiscount ? discount.type : type
                              );
                              if (
                                (model === SubscriptionType.PRO || model === SubscriptionType.PREMIUM) &&
                                (!hasDiscount || subscriptionStatus === 'past_due')
                              )
                                return;
                              onClickOnetimePurchase(!!discount && hasDiscount ? discount.type : type);
                            }}
                          >
                            {price}
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  </Collapse>
                </div>
              </div>
            </Grid>
          )
        )}
      </Grid>
      <Grid container className={classes.section}>
        <div className={classes.creditInfoOuterContainer}>
          <div className={classes.creditInfoContainer}>
            <div className={classes.creditInfoHeader}>{t('subscriptionTab.tabs.creditInfo.title')}</div>
            <ul className={classes.creditInfoList}>
              <li>{t('subscriptionTab.tabs.creditInfo.contents.0')}</li>
              <li>{t('subscriptionTab.tabs.creditInfo.contents.1')}</li>
              <li>{t('subscriptionTab.tabs.creditInfo.contents.2')}</li>
            </ul>
          </div>
          <div className={classes.creditUsageContainer}>
            <div className={classes.creditUsageHeader} onClick={() => setExpandedCreditUsage(!expandedCreditUsage)}>
              {t('subscriptionTab.tabs.creditUsage.title')}
              <ExpandMore className={classes.expandIcon} sx={expandedCreditUsage && { transform: 'rotate(-180deg)' }} />
            </div>
            <Collapse in={expandedCreditUsage} unmountOnExit>
              <div className={classes.creditUsageTable}>
                <div className={classes.tableHeader}>
                  <div className={classes.tableHeaderCell}>{t('subscriptionTab.tabs.creditUsage.generation')}</div>
                  <div className={classes.tableHeaderCell}>{t('subscriptionTab.tabs.creditUsage.cost')}</div>
                </div>
                {costPerService.generation.map(({ name, cost }) => (
                  <div className={classes.tableRow}>
                    <div className={classes.tableCell}>{name}</div>
                    <div className={classes.tableCell}>{cost}</div>
                  </div>
                ))}
              </div>
              <div className={classes.creditUsageTable}>
                <div className={classes.tableHeader}>
                  <div className={classes.tableHeaderCell}>{t('subscriptionTab.tabs.creditUsage.training')}</div>
                  <div className={classes.tableHeaderCell}>{t('subscriptionTab.tabs.creditUsage.cost')}</div>
                </div>
                {costPerService.training.map(({ name, cost }) => (
                  <div className={classes.tableRow}>
                    <div className={classes.tableCell}>{name}</div>
                    <div className={classes.tableCell}>{cost}</div>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        </div>
        <div className={classes.creditInfoImageContainer}>
          <img
            className={classes.creditInfoImage}
            src={selectedLanguage === 'ko' ? creditUsageKorImg : creditUsageEngImg}
            alt="credit-usage"
          />
        </div>
      </Grid>
      <Box className={classes.comparisonTableOuterContainer}>
        <div className={classes.comparisonTitle}>{t('subscriptionComparison.title')}</div>
        <TableContainer component={Paper} className={classes.comparisonTableContainer}>
          <Table className={classes.comparisonTable}>
            <TableHead>
              <TableRow className={classes.comparisonTableHeader}>
                <TableCell className={`${classes.comparisonTableHeaderCell} ${classes.stickyHeader}`}>
                  {t('subscriptionComparison.headers.feature')}
                </TableCell>
                {subscriptionComparison.headers.map(item => (
                  <TableCell className={classes.comparisonTableHeaderCell}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(subscriptionComparison.subscription)}
              <TableRow className={classes.comparisonTableRow}>
                <TableCell className={`${classes.comparisonTableTitle} ${classes.stickyHeader}`}>
                  {t('subscriptionComparison.headers.inference')}
                </TableCell>
                <TableCell colSpan={subscriptionComparison.headers.length} className={classes.comparisonTableCell} />
              </TableRow>
              {renderRows(subscriptionComparison.inference)}
              <TableRow className={classes.comparisonTableRow}>
                <TableCell className={`${classes.comparisonTableTitle} ${classes.stickyHeader}`}>
                  {t('subscriptionComparison.headers.faceSwap')}
                </TableCell>
                <TableCell colSpan={subscriptionComparison.headers.length} className={classes.comparisonTableCell} />
              </TableRow>
              {renderRows(subscriptionComparison.faceSwap)}
              <TableRow className={classes.comparisonTableRow}>
                <TableCell className={`${classes.comparisonTableTitle} ${classes.stickyHeader}`}>
                  {t('subscriptionComparison.headers.tti')}
                </TableCell>
                <TableCell colSpan={subscriptionComparison.headers.length} className={classes.comparisonTableCell} />
              </TableRow>
              {renderRows(subscriptionComparison.tti)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SubscriptionPage;
