import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    alignSelf: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  label: {
    display: 'flex',
    marginBottom: '2rem',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '85%',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },

  pageTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '0.2rem',
    fontSize: '2.5rem',
    color: '#fff',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepSubHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.83rem',
    },
    marginLeft: '0.3rem',
    marginTop: '0.4rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  inputSourceButtonContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#818181',
    padding: '0.2rem',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  dragAndDropContainer: {
    marginTop: '0.5rem',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#F2F2F2',
    backgroundColor: '#202020',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  durationText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
    color: '#fff',
    fontWeight: '300',
  },
  formLabelCol: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  dragAndDropHeader: {
    marginLeft: '0.5rem',
    marginTop: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  dragAndDropTextContainer: {
    height: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  acceptedFileOuterContainer: {
    display: 'flex',
  },
  acceptedFileInnerContainer: {
    marginLeft: '1rem',
    marginTop: '1.5rem',
    color: '#fff',
    fontWeight: '300',
    display: 'flex',
    alignItems: 'center',
    border: '0.1rem solid',
    padding: '0.5rem',
    borderRadius: '0.5rem',
  },
  acceptedFileDelete: {
    marginLeft: '0.5rem',
    width: '1.3rem',
    marginTop: '0.1rem',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRemainingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
    marginLeft: '1rem',
    color: '#fff',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      padding: '0.7rem 1rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0.7rem 2.5rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    cursor: 'pointer',
  },
  disabledButton: {
    backgroundColor: '#CAFF73',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '5rem',
  },
  laodingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  laodingText: {
    marginLeft: '1rem',
    color: '#fff',
  },
  laodingAnimation: {
    width: '25px',
    height: '25px',
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeaderCenter: {
    alignSelf: 'center',
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem',
    },
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  dragAndDropList: {
    paddingTop: '1rem!important',
    paddingBottom: '0!important',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '40px',
  },
  listItemAvatar: {
    minWidth: '40px',
  },
  avatar: {
    width: '25px',
    height: '25px',
  },
  audioIcon: {
    width: '20px',
    height: '20px',
  },
  listItemText: {
    color: '#fff',
  },
  noMargin: {
    margin: '0',
  },
  coverImg: {
    userSelect: 'none',
    width: '5em',
    height: '5em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.2rem',
  },
  deleteButtonImg: {
    //marginTop: "3.2rem",
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //fontSize: "px",
    cursor: 'pointer',
    borderRadius: '0.1rem',
    color: '#fff',
  },
  recordButtonContainer: {
    width: '24rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
    },
    width: '28rem',
    display: 'flex',
    marginLeft: '0.8rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    alignItems: 'flex-end',
  },
  anonymousContainer: {
    //paddingTop: '1rem!important',
    marginBottom: '-0.5rem',
  },
  radioButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '1rem',
    },
    display: 'flex!important',
    flexDirection: 'row!important',
    alignItems: 'center',
    justifyContent: 'start',
    height: '1rem',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem!important',
    },
    marginRight: '1rem',
    marginLeft: '0.3rem',
    fontWeight: '300',
    //fontSize: '1.1rem!important',
    color: '#fff',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
  },
  tagAutoComplete: {
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '20rem',
    maxWidth: '-webkit-fill-available',
    color: '#fff',
    fontSize: '16px!important',
    fontWeight: '400',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    padding: '0 !important',
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: '#292929',
  },
  labelInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
    },
    fontSize: '16px !important',
    maxWidth: '-webkit-fill-available',
    width: '20rem',
    height: '40px',
    color: '#9a9a9a',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export { useStyles };
