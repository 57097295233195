import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MyVoicePage.styles';

import moment from 'moment';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import ReactTimeAgo from 'react-time-ago';

import { ButtonGroup, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';

import DownloadModal from '../layout/DownloadModal';
import CommunityUploadModal from '../layout/CommunityUploadModal';

import ConfirmationModal from '../layout/ConfirmationModal';
import EditLabelModal from '../layout/EditLabelModal';
import EditCommunityModal from '../layout/EditCommunityModal';
import TrainingInfoModal from '../layout/TrainingInfoModal.js';
import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { appActions } from '../../actions/app.actions.js';
import {
  checkTrainingCompletion,
  checkFailedTrainingsAndReturnCredit,
  checkInferenceCompletion,
  checkFailedInferencesAndReturnCredit,
  updateTrainingById,
  updateCommunityItemById,
  deleteCommunityItemById,
  updateInferenceById,
  updateVocalExtractById,
  checkVocalExtractCompletion,
  fetchInferencesChunk,
  fetchTrainingsChunk,
  fetchVocalExtractsChunk,
  fetchPagedUserCommunityItems,
  fetchUserCommunityItems,
  getCommunitySampleSignedUrl,
  checkVideoRenderCompletion,
  updateVideoRenderById,
  fetchVideoRendersChunk,
  getVideoRenderSignedUrl,
  updateImage,
  likeCommunityItem,
  dislikeCommunityItem,
  fetchTrainingsLabel,
  fetchFaceSwapsChunk,
  updateFaceSwapById,
  checkFaceSwapCompletion,
  fetchRenderedVideoUrl,
  fetchTTIInferenceChunk,
  checkTTIInferenceCompletion,
  updateTTIInferenceById,
} from '../../services/page.services';
import HtmlInfoIcon from '../HtmlInfoIcon.js';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import infoIconImg from '../../img/infoIcon.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { showMessageV2 } from '../../utils/page.utils.js';

function MyVoicePage() {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alignment, setAlignment] = useState('music');
  //initData
  const [data, setData] = useState([]);
  const [communityItemsLiked, setCommunityItemsLiked] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  //query
  const [query, setQuery] = useState('');
  const [communityQuery, setCommunityQuery] = useState('');

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  //pagination
  const chunk = 50;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({});

  //modal
  const [downloadModalProps, setDownloadModalProps] = useState({});
  const [editModalProps, setEditModalProps] = useState({});
  const [editCommunityModalProps, setEditCommunityModalProps] = useState({});
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const [communityUploadProps, setCommunityUploadProps] = useState({});
  const [trainingInfoModalProps, setTrainingInfoModalProps] = useState({});

  //image accepted files
  const acceptedFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/webp': [],
    'image/heic': [],
    'image/jfif': [],
    'image/jpg': [],
  };

  //filter
  const [voice, setVoice] = useState([]);
  const [voiceId, setVoiceId] = useState([]);
  const [renderFilter, setRenderFilter] = useState(false);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  useEffect(() => {
    const debounced = _.debounce(() => initData(false), 300);

    if (userId) {
      debounced();
    }

    return () => {
      debounced.cancel();
    };
  }, [userId, alignment, query, voiceId]);

  const initVoice = async () => {
    try {
      const initialVoice = await fetchTrainingsLabel();
      const copiedVoice = initialVoice.map(item => ({
        label: item.voice.label,
        count: parseInt(item.count),
        trainingId: item.trainingId,
      }));
      setVoice(copiedVoice);
    } catch (err) {
      console.log(err);
      showMessageV2(dispatch, t('historyTab.modal.filterError'));
      setVoice([]);
    }
  };

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      if (alignment === 'voice') {
        let trainingsChunk;
        await checkFailedTrainingsAndReturnCredit();

        trainingsChunk = await fetchTrainingsChunk(chunk, scroll ? chunkPage : 0, query, signal);
        let trainings = trainingsChunk.rows;

        const incompleteList = _.filter(trainings, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkTrainingCompletion(userId, incompleteList);
          trainingsChunk = await fetchTrainingsChunk(chunk, scroll ? chunkPage : 0, query, signal);
          trainings = trainingsChunk.rows;
        }

        const cleanedData = trainings.map(
          ({
            id,
            label,
            status,
            createdAt,
            tier,
            shared,
            purchaseId,
            external,
            fileList,
            needMdx,
            errorFile,
            pipelineStatus,
          }) => {
            const parsedFileList = fileList ? JSON.parse(fileList) : null;
            return {
              id,
              label,
              createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
              status,
              shared,
              tag: tier === 'FREE' && !purchaseId ? `[${t('historyTab.freeVersionTag')}]` : '',
              fileList: parsedFileList,
              needMdx,
              external,
              errorFile,
              pipelineStatus,
            };
          }
        );

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(trainingsChunk.count);
      } else if (alignment === 'music') {
        let inferencesChunk;
        await checkFailedInferencesAndReturnCredit();

        inferencesChunk = await fetchInferencesChunk(chunk, scroll ? chunkPage : 0, query, signal, voiceId);
        let inferences = inferencesChunk.rows;

        const incompleteList = _.filter(inferences, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkInferenceCompletion(userId, incompleteList);
          inferencesChunk = await fetchInferencesChunk(chunk, scroll ? chunkPage : 0, query, signal, voiceId);
          inferences = inferencesChunk.rows;
        }
        const cleanedData = inferences.map(
          ({
            pitch,
            mixerSettings,
            voiceId,
            id,
            label,
            status,
            createdAt,
            needMdx,
            outputFormat,
            tier,
            deletedAt,
            sourceLabel,
            purchaseId,
            voice,
            watermark,
            pipelineStatus,
          }) => {
            let decodedMixerSettings;
            try {
              const decodedString = atob(mixerSettings);
              decodedMixerSettings = JSON.parse(decodedString);
            } catch (error) {
              decodedMixerSettings = null;
            }
            return {
              pitch,
              mixerSettings: decodedMixerSettings,
              trainingId: voiceId,
              id,
              label,
              createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
              status,
              needMdx,
              outputFormat,
              tag: tier === 'FREE' && (watermark || watermark === null) ? `[${t('historyTab.freePrefix')}]` : '',
              deletedAt,
              sourceLabel,
              trainingLabel: voice ? voice.label : 'AI voice not found',
              pipelineStatus,
            };
          }
        );

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(inferencesChunk.count);
      } else if (alignment === 'community') {
        const communityItems = await fetchPagedUserCommunityItems(1, chunk, query, null, 'recent', userId);
        setData(communityItems.rows);
        setFilteredData(communityItems.rows);
        setPaginationInfo(communityItems.pagination);
      } else if (alignment === 'vocalExtract') {
        let vocalExtractsChunk;
        vocalExtractsChunk = await fetchVocalExtractsChunk(chunk, scroll ? chunkPage : 0, query, signal);
        let vocalExtracts = vocalExtractsChunk.rows;

        const incompleteList = _.filter(vocalExtracts, {
          status: 'PENDING',
        });
        if (_.size(incompleteList) > 0) {
          await checkVocalExtractCompletion(userId, incompleteList);
          vocalExtractsChunk = await fetchVocalExtractsChunk(chunk, scroll ? chunkPage : 0, query, signal);
          vocalExtracts = vocalExtractsChunk.rows;
        }
        const cleanedData = vocalExtracts.map(({ id, label, status, createdAt, outputFormat }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            outputFormat,
            tag: '',
          };
        });

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(vocalExtractsChunk.count);
      } else if (alignment === 'faceSwap') {
        let faceSwapsChunk = await fetchFaceSwapsChunk(chunk, scroll ? chunkPage : 0, query, signal);
        let faceSwaps = faceSwapsChunk.rows;

        const incompleteList = _.filter(faceSwaps, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkFaceSwapCompletion(userId, incompleteList);
          faceSwapsChunk = await fetchFaceSwapsChunk(chunk, scroll ? chunkPage : 0, query, signal);
          faceSwaps = faceSwapsChunk.rows;
        }

        const cleanedData = faceSwaps.map(({ id, label, status, createdAt, watermark, deletedAt }) => ({
          id,
          label,
          createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
          status,
          deletedAt,
          watermark,
        }));

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(faceSwapsChunk.count);
      } else if (alignment === 'image') {
        let imageChunk;
        imageChunk = await fetchTTIInferenceChunk(chunk, scroll ? chunkPage : 0, query, signal);
        let images = imageChunk.rows;
        const incompleteList = _.filter(images, {
          status: 'PENDING',
        });
        if (_.size(incompleteList) > 0) {
          await checkTTIInferenceCompletion(userId, incompleteList);
          imageChunk = await fetchTTIInferenceChunk(chunk, scroll ? chunkPage : 0, query, signal);
          images = imageChunk.rows;
        }
        const cleanedData = images.map(({ id, label, status, createdAt, outputFormat, quantity }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            outputFormat,
            quantity,
            tag: '',
          };
        });

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(imageChunk.count);
      } else {
        let videoRendersChunk;
        videoRendersChunk = await fetchVideoRendersChunk(chunk, scroll ? chunkPage : 0, query, signal);
        let videoRenders = videoRendersChunk.rows;

        const incompleteList = _.filter(videoRenders, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkVideoRenderCompletion(userId, incompleteList);
          videoRendersChunk = await fetchVideoRendersChunk(chunk, scroll ? chunkPage : 0, query, signal);
          videoRenders = videoRendersChunk.rows;
        }
        const cleanedData = videoRenders.map(({ id, label, status, createdAt, watermark, deletedAt }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            deletedAt,
            watermark,
          };
        });

        if (scroll) {
          setData(prevData => [...prevData, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(videoRendersChunk.count);
      }
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const fetchMoreData = async () => {
    try {
      //setPage(page + 1);

      const communityItems = await fetchPagedUserCommunityItems(
        paginationInfo.next,
        chunk,
        query,
        null,
        'recent',
        userId
      );
      setData(prevData => [...prevData, ...communityItems.rows]);
      setFilteredData(prevData => [...prevData, ...communityItems.rows]);
      setPaginationInfo(communityItems.pagination);
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const searchItems = searchValue => {
    setCommunityQuery(searchValue);
    setFilteredData(
      data.filter(item => {
        return Object.values(item).join('').toLowerCase().includes(communityQuery.toLowerCase());
      })
    );
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const handleShare = async id => {
    setCommunityUploadProps({
      open: true,
      selectedModelId: id,
      onCommunityModalClose: () => {
        setCommunityUploadProps({ open: false });
      },
    });
  };

  const handleCommunityEdit = async (id, label, anonymous) => {
    setEditCommunityModalProps({
      open: true,
      label,
      anonymous,
      onEdit: async (changedLabel, changedAnonymous, changedImage) => {
        setEditCommunityModalProps({ open: true, loading: true });
        const updatedCommunityItem = await updateCommunityItemById(
          id,
          { label: changedLabel, anonymous: changedAnonymous },
          changedImage
        );
        // console.log(updatedCommunityItem);

        const copiedFilteredData = filteredData.map(obj => {
          if (obj.id === updatedCommunityItem.id) {
            let newObj = { ...obj };
            newObj.image = updatedCommunityItem.image; //URL.createObjectURL(file);
            newObj.label = updatedCommunityItem.label;
            newObj.username = updatedCommunityItem.username;
            newObj.anonymous = updatedCommunityItem.anonymous;
            return newObj;
            //return { ...obj, likedNumber: obj.likedNumber - 1 };
          }
          return obj;
        });

        const copiedData = data.map(obj => {
          if (obj.id === id) {
            let newObj = { ...obj };
            newObj.image = updatedCommunityItem.image; //URL.createObjectURL(file);
            newObj.label = updatedCommunityItem.label;
            newObj.username = updatedCommunityItem.username;
            newObj.anonymous = updatedCommunityItem.anonymous;
            return newObj;
          }
          return obj;
        });
        setData(copiedData);
        setFilteredData(copiedFilteredData);
        setEditCommunityModalProps({ open: false });
      },
      onEditCommunityModalClose: () => {
        setEditCommunityModalProps({ open: false });
      },
    });
  };

  const handleDelete = async (id, type, alignment) => {
    setConfirmationModalProps({
      open: true,
      message:
        alignment === 'voice' && type !== 'FAILED'
          ? t('historyTab.confirmModelDelete')
          : type !== 'FAILED' && t('historyTab.confirmDelete'),
      onConfirmation: async response => {
        if (response === true) {
          setConfirmationModalProps({ open: true, loading: true });
          if (alignment === 'voice') {
            await updateTrainingById(id, { deletedAt: moment() });
          } else if (alignment === 'music') {
            await updateInferenceById(id, { deletedAt: moment() });
            if (_.size(data) === 1) {
              setVoiceId([]);
              setRenderFilter(!renderFilter);
            }
          } else if (alignment === 'community') {
            await deleteCommunityItemById(id, { deletedAt: moment() });
          } else if (alignment === 'vocalExtract') {
            await updateVocalExtractById(id, { deletedAt: moment() });
          } else if (alignment === 'faceSwap') {
            await updateFaceSwapById(id, { deletedAt: moment() });
          } else if (alignment === 'image') {
            await updateTTIInferenceById(id, { deletedAt: moment() });
          } else {
            await updateVideoRenderById(id, { deletedAt: moment() });
          }

          if (alignment !== 'community') {
            let copiedData = [...data];
            copiedData = copiedData.filter(obj => obj.id !== id);
            setData(copiedData);
          } else {
            //let copiedCommunityItems = [...communityItems];
            const copiedCommunityItems = data.filter(item => {
              return item.id !== id;
            });
            setData(copiedCommunityItems);
            setFilteredData(copiedCommunityItems);
          }
        }
        setConfirmationModalProps({ open: false });
      },
      onClose: () => setConfirmationModalProps({ open: false }),
    });
  };

  const handleEdit = async (id, label, alignment) => {
    setEditModalProps({
      open: true,
      label,
      alignment,
      onEdit: async changedLabel => {
        setEditModalProps({ open: true, loading: true });
        if (alignment === 'voice') {
          await updateTrainingById(id, { label: changedLabel });
        } else if (alignment === 'music') {
          await updateInferenceById(id, { label: changedLabel });
        } else if (alignment === 'community') {
          await updateCommunityItemById(id, { label: changedLabel });
        } else if (alignment === 'vocalExtract') {
          await updateVocalExtractById(id, { label: changedLabel });
        } else if (alignment === 'faceSwap') {
          await updateFaceSwapById(id, { label: changedLabel });
        } else if (alignment === 'image') {
          await updateTTIInferenceById(id, { label: changedLabel });
        } else {
          await updateVideoRenderById(id, { label: changedLabel });
        }
        let copiedData = data.map(obj => {
          if (obj.id === id) {
            return { ...obj, label: changedLabel };
          }
          return obj;
        });
        setData(copiedData);

        if (alignment === 'community') {
          setData(copiedData);
          let copiedFilteredData = filteredData.map(obj => {
            if (obj.id === id) {
              return { ...obj, label: changedLabel };
            }
            return obj;
          });
          setData(copiedData);
          setFilteredData(copiedFilteredData);
        }
        setEditModalProps({ open: false });
      },
      onClose: () => {
        setEditModalProps({ open: false });
      },
    });
  };

  const handlePlay = async (communityItemId, label) => {
    if (selectedCommunityId === communityItemId) {
      setPlaying(true);
    } else {
      setPlaying(false);
      const sampleVoiceUrl = await getCommunitySampleSignedUrl(communityItemId);
      const sampleVoiceBlob = await getBlobFromS3Url(sampleVoiceUrl);
      if (sampleVoiceBlob.size > 2000) {
        const audio = await new Audio(URL.createObjectURL(sampleVoiceBlob.slice(0, sampleVoiceBlob.size, `audio/mp3`)));
        setSelectedAudio(audio);
        setPlaying(true);
        setSelectedCommunityId(communityItemId);
      }
    }
  };

  const handlePlay2 = _.debounce(handlePlay, 500, { leading: true });

  const handlePause = async (communityItemId, label) => {
    setPlaying(false);
  };

  const handleLike = async communityItemId => {
    likeCommunityItem(communityItemId);
    const copiedData = data.map(obj => {
      if (obj.id === communityItemId) {
        return {
          ...obj,
          likedNumber: Number(obj.likedNumber) + 1,
          liked: true,
        };
      }
      return obj;
    });
    const copiedFilteredData = filteredData.map(obj => {
      if (obj.id === communityItemId) {
        return {
          ...obj,
          likedNumber: Number(obj.likedNumber) + 1,
          liked: true,
        };
      }
      return obj;
    });

    setData(copiedData);
    setFilteredData(copiedFilteredData);
  };

  const handleDislike = async communityItemId => {
    dislikeCommunityItem(communityItemId);
    const copiedData = data.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;
        return newObj;
      }
      return obj;
    });
    const copiedFilteredData = filteredData.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;
        return newObj;
        //return { ...obj, likedNumber: obj.likedNumber - 1 };
      }
      return obj;
    });

    setData(copiedData);
    setFilteredData(copiedFilteredData);
  };

  const handleDownload = async (id, needMdx, label, alignment, outputFormat, tag, quantity) => {
    setDownloadModalProps({
      open: true,
      id,
      needMdx,
      label,
      alignment,
      outputFormat,
      tag,
      quantity,
      onClose: () => {
        setDownloadModalProps({ open: false });
      },
    });
  };

  const handleVideoRenderDownload = async (id, label) => {
    const fileName = `${id}-complete.mp4`;
    const downloadName = `${label}.mp4`;
    try {
      const url = await getVideoRenderSignedUrl(id, fileName, downloadName);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      if (tempLink.href) {
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    } catch (e) {
      console.log('error downloading video render: ', e);
    }
  };

  const handleFaceSwapDownload = async (id, label) => {
    try {
      const { url } = await fetchRenderedVideoUrl(id);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.href = blobUrl;

      let fileName = label ? label : 'download';
      if (!fileName.toLowerCase().endsWith('.mp4')) {
        fileName += '.mp4';
      }

      tempLink.download = fileName;

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);

      window.URL.revokeObjectURL(blobUrl);
    } catch (e) {
      console.log('Download error:', e);
      // showMessageV2(dispatch, t('faceSwapTab.error.downloadFailed'));
    }
  };

  const handleTrainingInfo = async (label, external, fileList, needMdx, shared, errorFile, pipelineStatus, status) => {
    setTrainingInfoModalProps({
      open: true,
      label,
      external,
      fileList,
      needMdx,
      shared,
      errorFile,
      pipelineStatus,
      status,
      onTrainingInfoModalClose: () => {
        setTrainingInfoModalProps({ open: false });
      },
    });
  };

  const classes = useStyles();
  return (
    <>
      <CommunityUploadModal {...communityUploadProps} />
      <DownloadModal {...downloadModalProps} />
      <ConfirmationModal {...confirmationModalProps} />
      <EditLabelModal {...editModalProps} />
      <EditCommunityModal {...editCommunityModalProps} />
      <TrainingInfoModal {...trainingInfoModalProps} />
      <div className={classes.pageTitle}>
        {t('historyTab.title')}
        <HtmlTooltip
          title={
            <div className={'globalTooltipContainer'}>
              <div className={'globalTooltipTextContainer'}>
                <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                <div className={'globalTooltipText'}>{t('historyTab.tooltip.contents.0')}</div>
              </div>
              <div className={'globalTooltipTextContainer'}>
                <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                <div className={'globalTooltipText'}>{t('historyTab.tooltip.contents.1')}</div>
              </div>
              <div className={'globalTooltipTextContainer'}>
                <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                <div className={'globalTooltipText'}>{t('historyTab.tooltip.contents.2')}</div>
              </div>
            </div>
          }
        >
          <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
        </HtmlTooltip>
      </div>

      <div className={classes.separater} />
      <div className={classes.inputSourceBarContainer}>
        <ButtonGroup className={classes.inputSourceButtonContainer}>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'music' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'music') {
                setAlignment('music');
                setData([]);
                setQuery('');
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.inference.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'voice' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'voice') {
                setAlignment('voice');
                setData([]);
                setQuery('');
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.training.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'vocalExtract' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'vocalExtract') {
                setAlignment('vocalExtract');
                setData([]);
                setQuery('');
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.vocalExtract.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'image' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'image') {
                setAlignment('image');
                setData([]);
                setQuery('');
                setChunkPage(0);
                setVoiceId([]);
              }
              console.log(data);
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.image.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'videoEditor' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'videoEditor') {
                setAlignment('videoEditor');
                setData([]);
                setQuery('');
                setChunkPage(0);
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.videoEditor.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'faceSwap' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'faceSwap') {
                setAlignment('faceSwap');
                setData([]);
                setQuery('');
                setChunkPage(0);
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.faceSwap.title')}
          </Button>
          <Button
            className={`${classes.inputSourceButton} ${alignment == 'community' ? classes.selected : ''}`}
            onClick={() => {
              if (alignment != 'community') {
                setAlignment('community');
                setData([]);
                setQuery('');
                setChunkPage(0);
                setVoiceId([]);
              }
            }}
            style={{ fontSize: 'inherit' }}
          >
            {t('historyTab.tabs.community.title')}
          </Button>
        </ButtonGroup>
      </div>

      <input
        className={classes.searchbar}
        placeholder={t('historyTab.searchbar')}
        value={query}
        onChange={query => setQuery(query.target.value)}
      />

      {/*alignment === "community" && (
        <input
          className={classes.searchbar}
          placeholder={t('historyTab.searchbar')}
          value={communityQuery}
          onChange={communityQuery => searchItems(communityQuery.target.value)}
        />
      )*/}

      {alignment === 'music' && userId && (
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          size="small"
          sx={theme => ({
            borderRadius: '0.2rem',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiInputBase-sizeSmall': {
              [theme.breakpoints.down('sm')]: {
                padding: '0.2rem 3.5rem 0.2rem 0.5rem!important',
              },
              padding: '0.2rem 3.5rem 0.2rem 1rem !important',
              minHeight: '2.5rem',
            },
            '& .MuiInputBase-input': {
              '&::placeholder': {
                color: '#818181!important',
                opacity: '1!important',
              },
              //fontFamily: 'TheJamsilRegular!important',
              fontSize: '0.9rem!important',
              padding: '0!important',
              color: '#000!important',
            },
          })}
          className={classes.voiceAutoComplete}
          options={voice}
          onChange={(event, newValues) => {
            setVoiceId(newValues.map(voice => voice.trainingId));
          }}
          key={renderFilter}
          loading={true}
          loadingText={t('historyTab.pagination.loader')}
          onOpen={() => initVoice()}
          renderInput={params =>
            voiceId.length > 0 ? (
              <TextField {...params} />
            ) : (
              <TextField {...params} placeholder={t('historyTab.voice')} />
            )
          }
        />
      )}

      {alignment !== 'community' && alignment !== 'videoEditor' && alignment !== 'faceSwap' && (
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={data.length}
          next={() => initData(true)}
          hasMore={data.length < length}
          loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
        >
          {data
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
            .map(
              ({
                pitch,
                mixerSettings,
                id,
                label,
                createdAt,
                status,
                needMdx,
                tag,
                outputFormat,
                sourceLabel,
                trainingLabel,
                shared,
                external,
                fileList,
                trainingId,
                errorFile,
                pipelineStatus,
                quantity,
              }) => {
                return (
                  <div
                    className={`${classes.card} ${(alignment === 'music' || alignment === 'vocalExtract') && status === 'COMPLETE' && classes.clickableCard}`}
                    onClick={() => {
                      if ((alignment === 'music' || alignment === 'vocalExtract') && status === 'COMPLETE') {
                        dispatch(appActions.selectEditorSource({ id, label, outputFormat, alignment }));
                        navigate('/video-editor');
                      }
                    }}
                  >
                    <div className={classes.cardLabel}>
                      {label} {tag}
                      <EditIcon
                        className={classes.editIcon}
                        onClick={event => {
                          event.stopPropagation();
                          handleEdit(id, label, alignment);
                        }}
                      />
                    </div>
                    <div className={classes.cardTimestamp}>{createdAt}</div>
                    {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                    {status === 'PENDING' && (
                      <div className={classes.cardStatus}>
                        {pipelineStatus
                          ? t(`pipeline.inferenceStatusMessage.${pipelineStatus}`)
                          : t('historyTab.inProgress')}
                      </div>
                    )}
                    {(alignment == 'music' || alignment == 'vocalExtract' || alignment == 'image') &&
                      status === 'COMPLETE' && (
                        <div
                          className={classes.cardAction}
                          onClick={event => {
                            event.stopPropagation();
                            handleDownload(id, needMdx, label, alignment, outputFormat, tag, quantity);
                          }}
                        >
                          <DownloadIcon />
                        </div>
                      )}
                    {alignment == 'music' && (
                      <div
                        className={classes.infoIconAction}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        <HtmlInfoIcon
                          title={
                            <div className={classes.infoIconContainer}>
                              <div className={classes.infoIconTextContainer}>
                                <div className={classes.infoIconTextLine}>
                                  <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.source')}</div>
                                  <div className={classes.infoIconText}>: {sourceLabel}</div>
                                </div>
                                <div className={classes.infoIconTextLine}>
                                  <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.voice')}</div>
                                  <div className={classes.infoIconText}>: {trainingLabel}</div>
                                </div>
                                <div className={classes.infoIconTextLine}>
                                  <div className={classes.infoIconTextBold}>{t('historyTab.infoIcon.pitch')}</div>
                                  <div className={classes.infoIconText}>: {pitch}</div>
                                </div>

                                {mixerSettings?.mixerUsed ? (
                                  <div>
                                    <div className={classes.infoIconTextLine}>
                                      <div className={classes.infoIconTextBold}>
                                        {t('historyTab.infoIcon.mixing.title')}:
                                      </div>
                                    </div>
                                    <div className={classes.infoIconTextLine}>
                                      <ul className={classes.infoIconList}>
                                        <li className={classes.infoIconListItem}>
                                          {t('historyTab.infoIcon.mixing.vocalLevel')}: {mixerSettings.vocalLevel}dB
                                        </li>
                                        <li className={classes.infoIconListItem}>
                                          {t('historyTab.infoIcon.mixing.reverbLevel')}: {mixerSettings.reverbLevel}
                                        </li>
                                        <li className={classes.infoIconListItem}>
                                          {t('historyTab.infoIcon.mixing.reverbLength')}: {mixerSettings.reverbLength}s
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                                <div className={classes.infoIconTextLine}>
                                  <div className={classes.infoIconTextBold}>{t('historyTab.trainingInfo.status')}</div>
                                  <div className={classes.infoIconText}>
                                    :{' '}
                                    {status === 'COMPLETE'
                                      ? t('pipeline.trainingStatusMessage.complete')
                                      : pipelineStatus
                                        ? t(`pipeline.inferenceStatusMessage.${pipelineStatus}`)
                                        : status === 'FAILED'
                                          ? t('pipeline.inferenceStatusMessage.generalMessage')
                                          : t('pipeline.inferenceStatusMessage.starting')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <img className={classes.infoIconImg} src={infoIconImg} alt="tooltip-img" />
                        </HtmlInfoIcon>
                      </div>
                    )}
                    {/* {alignment == 'music' && status !== 'COMPLETE' && (
                      <div className={classes.infoIconAction}>
                        <HtmlInfoIcon
                          title={
                            <div className={classes.infoIconContainer}>
                              <div className={classes.infoIconTextContainer}>
                                <div className={classes.infoIconTextLine}>
                                  <div className={classes.infoIconText}>
                                    {pipelineStatus
                                      ? t(`pipeline.inferenceStatusMessage.${pipelineStatus}`)
                                      : status === 'FAILED'
                                        ? t('pipeline.inferenceStatusMessage.generalMessage')
                                        : t('pipeline.inferenceStatusMessage.starting')}
                                  </div>ßß
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <img className={classes.infoIconImg} src={infoIconImg} alt="tooltip-img" />
                        </HtmlInfoIcon>
                      </div>
                    )} */}
                    {alignment == 'voice' && status === 'COMPLETE' && !shared && (
                      <div className={classes.cardAction}>
                        <ShareOutlinedIcon
                          disabled={shared}
                          onClick={() => {
                            handleShare(id);
                          }}
                        />
                      </div>
                    )}
                    {alignment === 'voice' && (
                      <div className={classes.cardAction}>
                        <img
                          className={classes.infoIconImg}
                          src={infoIconImg}
                          alt="tooltip-img"
                          onClick={() =>
                            handleTrainingInfo(
                              label,
                              external,
                              fileList,
                              needMdx,
                              shared,
                              errorFile,
                              pipelineStatus,
                              status
                            )
                          }
                        />
                      </div>
                    )}
                    {(status === 'COMPLETE' || status === 'FAILED') && (
                      <div
                        className={classes.cardAction}
                        onClick={event => {
                          event.stopPropagation();
                          handleDelete(id, status, alignment);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                  </div>
                );
              }
            )}
        </InfiniteScroll>
      )}
      {alignment === 'community' && (
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={paginationInfo.has_next}
          loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
        >
          {data
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
            .map(
              ({
                id,
                gender,
                label,
                username,
                createdAt,
                status,
                trainingLabel,
                image,
                likedNumber,
                numberUsed,
                anonymous,
                liked,
              }) => {
                return (
                  <Grid className={classes.communityCard} item container key={id}>
                    <Grid item xs={'auto'} sm={'auto'} md={'auto'}>
                      <div
                        container
                        className={classes.coverImg}
                        style={{
                          backgroundImage: `url(${image})`,
                          backgroundSize: 'contain',
                        }}
                      ></div>
                    </Grid>
                    <Grid
                      container
                      className={classes.communityCardSecondColumn}
                      //style={{ border: "1px solid #fafafa" }}
                      item
                      xs
                      sm
                      md
                    >
                      <Grid container item>
                        <Grid container item xs={9} sm={10} md={10}>
                          <div className={classes.communityCardLabel}>{label} </div>
                        </Grid>
                        <Grid container item className={classes.playButtonGrid} xs={3} sm={2} md={2}>
                          {playing && id === selectedCommunityId ? (
                            <PauseCircleOutlineIcon
                              className={classes.playButtonImg}
                              onClick={event => {
                                event.stopPropagation();
                                handlePause(id, label);
                              }}
                            />
                          ) : (
                            <PlayCircleOutlineIcon
                              className={classes.playButtonImg}
                              onClick={event => {
                                event.stopPropagation();
                                handlePlay2(id, label);
                              }}
                            />
                          )}
                          <EditIcon
                            onClick={() => handleCommunityEdit(id, label, anonymous)}
                            className={classes.editIcon}
                          />
                          <DeleteIcon
                            onClick={() => handleDelete(id, status, alignment)}
                            className={classes.communityDeleteButtonImg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <div className={classes.communityCardUsername}>{username}</div>
                      </Grid>
                      <Grid
                        container
                        item
                        style={{
                          maxHeight: '2rem',
                        }}
                      >
                        <Grid
                          className={classes.communityCreationsGrid}
                          container
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          style={{
                            maxHeight: '1.1rem',
                          }}
                        >
                          <div className={classes.communityNumberPlayed}>{numberUsed}</div>
                          <div className={classes.communityCreations}>{'creations'}</div>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          style={{
                            alignItems: 'flex-end',
                            maxHeight: '1.1rem',
                          }}
                        >
                          <div className={classes.communityCardTimestamp}>
                            <ReactTimeAgo date={createdAt} locale="en-US" />
                          </div>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          className={classes.communityCardLikes}
                          style={{
                            maxHeight: '2rem',
                          }}
                        >
                          {!liked && (
                            <FavoriteBorderRoundedIcon
                              className={classes.favoriteImg}
                              onClick={() => handleLike(id)}
                              fontSize="inherit"
                            />
                          )}

                          {liked && (
                            <FavoriteIcon
                              className={classes.favoriteFullImg}
                              onClick={() => handleDislike(id)}
                              fontSize="inherit"
                            />
                          )}
                          <div className={classes.numberLikes}>{likedNumber}</div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            )}
        </InfiniteScroll>
      )}
      {alignment === 'videoEditor' && (
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={data.length}
          next={() => initData(true)}
          hasMore={data.length < length}
          loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
        >
          {data
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
            .map(({ id, label, createdAt, status }) => {
              return (
                <div className={classes.card}>
                  <div className={classes.cardLabel}>
                    {label}
                    <EditIcon onClick={() => handleEdit(id, label, alignment)} className={classes.editIcon} />
                  </div>
                  <div className={classes.cardTimestamp}>{createdAt}</div>
                  {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                  {status === 'PENDING' && <div className={classes.cardStatus}>{t('historyTab.inProgress')}</div>}
                  {status === 'COMPLETE' && (
                    <div className={classes.cardAction}>
                      <DownloadIcon
                        onClick={() => {
                          handleVideoRenderDownload(id, label);
                        }}
                      />
                    </div>
                  )}
                  {(status === 'COMPLETE' || status === 'FAILED') && (
                    <div className={classes.cardAction}>
                      <DeleteIcon onClick={() => handleDelete(id, status, alignment)} />
                    </div>
                  )}
                </div>
              );
            })}
        </InfiniteScroll>
      )}
      {alignment === 'faceSwap' && (
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={data.length}
          next={() => initData(true)}
          hasMore={data.length < length}
          loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
        >
          {data
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
            .map(({ id, label, createdAt, status }) => {
              return (
                <div className={classes.card}>
                  <div className={classes.cardLabel}>
                    {label}
                    <EditIcon onClick={() => handleEdit(id, label, alignment)} className={classes.editIcon} />
                  </div>
                  <div className={classes.cardTimestamp}>{createdAt}</div>
                  {status === 'FAILED' && <div className={classes.cardStatus}>{t('historyTab.failed')}</div>}
                  {status === 'PENDING' && <div className={classes.cardStatus}>{t('historyTab.inProgress')}</div>}
                  {status === 'COMPLETE' && (
                    <div className={classes.cardAction}>
                      <DownloadIcon
                        onClick={() => {
                          handleFaceSwapDownload(id, label);
                        }}
                      />
                    </div>
                  )}
                  {(status === 'COMPLETE' || status === 'FAILED') && (
                    <div className={classes.cardAction}>
                      <DeleteIcon onClick={() => handleDelete(id, status, alignment)} />
                    </div>
                  )}
                </div>
              );
            })}
        </InfiniteScroll>
      )}
    </>
  );
}
export default MyVoicePage;
